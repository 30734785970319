@media all and (max-width: 1550px) {
  .container {
    max-width: 1440px;
    padding: 0 !important;
    margin: 0 auto;
  }
  header {
    padding: 30px 0 0 0;
  }
  .hero-section img {
    max-width: 400px;
  }
  .welcome-section .welcome-title {
    font-size: 40px;
  }
  .welcome-section p, .portfolio p {
    font-size: 20px;
    line-height: 28px;
  }
  .portfolio {
    width: 100%;
    overflow: hidden;
  }
  .portfolio .right-slider .webimage .img2 {
    width: 775px;
    left: 85px;
    top: 12px;
    height: 409px;
  }
  .vision-section {
    padding-top: 350px;
  }
  .left-slider .text {
    padding-left: 50px;
    padding-top: 50px;
  }
  .portfolio h2 {
    padding-left: 50px;
  }
  .portfolio .right-slider .slick-list, .portfolio .right-slider .webimage {
    height: 595px;
  }
  .portfolio .right-slider .webimage::before {
    bottom: 36px;
    width: 260px;
    height: 526px;
  }
  .portfolio .right-slider .webimage .img1 {
    top: 23px;
    margin-left: 6px;
    width: 249px;
    height: 517px;
  }
}
@media all and (max-width: 1550px) and (max-width: 1400px) {
  .container {
    max-width: 1300px;
  }
  .nav ul li {
    margin-right: 20px;
  }
  .expertise-list .block {
    min-width: 150px;
  }
  .expertise-list .block p {
    font-size: 14px;
  }
  .expertise-list .block img {
    height: 80px;
  }
  .left-slider {
    padding-top: 0;
  }
  .portfolio .right-slider .webimage .img2 {
    width: 685px;
  }
}
@media all and (max-width: 1550px) and (min-width: 1300px) and (max-width: 1400px) {
  .development-process .development-left {
    flex-basis: 30%;
    max-width: 30%;
  }
  .development-process .development-right {
    flex-basis: 70%;
    max-width: 70%;
  }
  .process-card {
    min-height: 300px;
  }
}
@media all and (max-width: 991px) {
  .logo img {
    width: 200px;
  }
  header {
    padding-top: 15px;
  }
  .container {
    padding: 0 15px !important;
  }
  .hero-section {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .hero-section img {
    max-width: 300px;
    display: block;
    margin: 0 auto;
  }
  .hero-section .pic {
    order: 1;
    overflow: hidden;
    background: #f9f9f9;
    margin-bottom: 15px;
  }
  .hero-section .content {
    order: 2;
  }
  .hero-section .pic, .hero-section .content,
  .welcome-section .content, .welcome-section .pic {
    flex-basis: 100%;
    max-width: 100%;
  }
  .hero-section .content {
    text-align: center !important;
  }
  .hero-section h1 {
    font-size: 40px;
    line-height: 50px;
  }
  .welcome-section {
    padding-top: 0;
  }
  .welcome-section figure {
    width: 300px;
    height: 300px;
    margin: 0 auto;
    top: 0;
  }
  .welcome-section .pic {
    text-align: center;
  }
  .welcome-section .welcome-title {
    font-size: 28px;
    margin: 30px 0;
  }
  .welcome-section::before {
    height: 80%;
  }
  .home-services {
    text-align: center;
  }
  .section-title::before {
    display: none;
  }
  .bg-grey .section-title h2 {
    width: 100%;
    padding: 0;
  }
  .bg-grey .section-title p {
    padding: 0;
  }
  .home-expertise, .development-process {
    text-align: center;
    padding: 30px 0 !important;
  }
  .section-title h2, .bg-grey .section-title p {
    width: 100%;
    text-align: center;
  }
  .expertise-list .block img {
    height: 50px;
  }
  .bg-grey .section-title h2, .section-title h2 {
    font-size: 32px;
  }
  .process-card figure {
    margin: 0 auto 15px auto !important;
  }
  .business-model {
    padding: 30px 0 !important;
  }
  .letDiscuss .card {
    display: block;
  }
  .portfolio {
    padding: 30px 0 !important;
    width: 100%;
    overflow: hidden;
    text-align: center;
  }
  .portfolio::before {
    width: 100%;
  }
  .portfolio .right-slider {
    order: 1;
    right: 0 !important;
  }
  .portfolio .left-slider {
    order: 2;
  }
  .portfolio .right-slider .webimage::before {
    width: 78px;
    height: 161px;
    left: 1px;
  }
  .portfolio .right-slider .webimage .img1 {
    top: 214px;
    margin-left: 6px;
    width: 153px;
    height: 325px;
  }
  .portfolio h2 {
    padding-left: 0;
    text-align: center;
    margin-bottom: 50px;
  }
  .portfolio .right-slider .slick-list, .portfolio .right-slider .webimage {
    height: 220px;
  }
  .portfolio .left-slider {
    padding: 0 !important;
  }
  .portfolio .right-slider .webimage .img1 {
    top: 8px;
    margin-left: 1px;
    width: 75px;
    height: 157px;
    border-radius: 12px;
  }
  .portfolio .right-slider .webimage .img1 img {
    border-radius: 12px;
  }
  .portfolio .right-slider .webimage .img2 {
    width: 217px;
    left: 1px;
    height: 135px;
    top: -6px;
  }
  .left-slider .text {
    padding: 0 !important;
  }
  .portfolio h3 {
    margin: 0;
    padding: 0;
  }
  .welcome-section p, .portfolio p {
    font-size: 18px;
    line-height: 24px;
  }
  .blog {
    padding: 30px 0 !important;
    text-align: center;
  }
  .display-none {
    display: none !important;
  }
  .portfolio .right-slider, .portfolio .left-slider {
    max-width: 300px;
    margin: 0 auto !important;
    overflow: hidden;
    display: block;
    padding: 0 !important;
  }
  .footer-socail ul {
    text-align: center;
    width: 100%;
    display: block;
  }
  .footer-socail ul li a img {
    width: 20px;
  }
  .footer-socail ul li a {
    width: 50px;
    height: 50px;
    padding: 5px;
  }
  .footer-socail ul li {
    padding: 0;
    margin: 0 5px;
  }
  .footer-socail {
    padding-left: 0;
    width: 100%;
    text-align: center;
  }
  .footer-middle {
    text-align: center;
  }
  .footer-middle ul li {
    text-align: center !important;
  }
  .footer-middle ul li a {
    display: block;
    width: 100%;
  }
  .footer-bottom {
    margin-top: 50px !important;
  }
  .portfolio h3 {
    font-size: 24px;
    line-height: 28px;
  }
  .blog .block {
    padding: 0 !important;
    margin-bottom: 30px;
  }
  .services-list-block .services-list-card img {
    position: relative;
    left: auto;
    top: auto;
  }
  .solution-top-section {
    padding: 10px 0 30px 0 !important;
    text-align: center;
  }
  .order1 {
    order: 1 !important;
  }
  .order2 {
    order: 2 !important;
  }
  .weoffer-section {
    padding: 30px 0 !important;
    text-align: center;
  }
  .weoffer-section .pic {
    margin-bottom: 30px;
  }
  .weoffer-section h2 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .weoffer-section p {
    font-size: 16px !important;
    line-height: 22px !important;
  }
  .about-top-section figure {
    margin: 0 auto 15px auto;
  }
  .about-top-section {
    text-align: center;
  }
  .vision-section {
    background: #2B74E2;
    text-align: center;
  }
  .vision-section .card figure img {
    width: 280px;
    margin: 0 auto;
    display: block;
  }
  .about-experience-section .block {
    padding: 0 !important;
    width: 100%;
    display: block;
    border: none;
    border-bottom: solid 1px #ccc;
    padding-bottom: 30px !important;
    margin-bottom: 30px !important;
  }
  .contact-page-section h1 {
    font-size: 32px;
  }
  .business-model .right-block ul li {
    display: none !important;
  }
  .letDiscuss {
    text-align: center;
  }
  .footer-bottom ul li a img {
    height: 40px;
  }
}/*# sourceMappingURL=responsive.css.map */