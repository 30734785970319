.text-primary {
  color: #2B74E2;
}

.bg-primary {
  background: #2B74E2;
}

.bg-secondary {
  background: #002E69;
}

.bg-grey {
  background: #EFF0FA;
}

.section-title {
  position: relative;
  margin: 30px 0 30px 0;
}
.section-title h2 {
  font-weight: bold;
  background: #fff;
  position: relative;
  z-index: 9;
  padding-right: 15px;
}
.section-title p {
  background: #fff;
  position: relative;
  z-index: 9;
  padding-left: 15px;
}

.bg-grey .section-title h2 {
  background: #EFF0FA;
}
.bg-grey .section-title p {
  background: #EFF0FA;
}

.section-title::before {
  content: "";
  width: 100%;
  height: 1px;
  background: #000;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  margin-top: 1px;
  z-index: 1;
}/*# sourceMappingURL=section-title.css.map */