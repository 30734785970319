@import "../../components/theme-constants/color/color.scss";
.section-title {
    position: relative;
    margin: 30px 0 30px 0;
    h2 {
        font-weight: bold;
        background: #fff;
        position: relative;
        z-index: 9;
        padding-right: 15px;
    }
    p {
        background: #fff;
        position: relative;
        z-index: 9;
        padding-left: 15px;
    }
}
.bg-grey .section-title {
    h2 {
        background: $grey-color;
    }
    p {
        background: $grey-color;
    }
}
.section-title::before {
    content: "";
    width: 100%;
    height: 1px;
    background: #000;
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    margin-top: 1px;
    z-index: 1;
}
