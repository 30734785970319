.expertise-list .block {
  text-align: center;
  min-width: 180px;
}

.expertise-list .block img {
  height: 100px;
  filter: grayscale(100%);
}

.expertise-list .block p {
  padding: 10px 0 0 0;
  margin: 0;
  color: #2E2727;
  font-weight: 400;
}

.expertise-list .block a {
  text-decoration: none;
}/*# sourceMappingURL=expertise.css.map */