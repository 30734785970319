.footer-middle {position: relative; overflow: hidden;}
.footer-middle::before{content: ""; position: absolute; left: -150px; bottom: -150px; width: 300px; height: 300px; background: url('../../images/footer-link.svg');}
.footer-middle::after{content: ""; position: absolute; right: -150px; top: 50%; margin-top: -150px; width: 300px; height: 300px; background: url('../../images/footer-link.svg');}

.footer-middle  a{text-decoration: none;}

.footer-top{margin-bottom: -18px; position: relative; top: 18px;}
.footer-logo a{display: block; position: relative;}
.footer-logo a img{position: relative; z-index: 2; width: 100%;}
.footer-logo a::before{content: ""; position: absolute; left: -5px; top: 0; width: 150px; height: 150px; background: #fff; z-index: 1; border-radius: 70%;}

.footer-middle .footer-title{font-size: 16px; font-weight: 800; margin-bottom: 15px;}

.footer-socail ul li{display: inline-block; width: auto; margin: 0 10px;}
.footer-socail ul li a{display: block; width: 70px; height: 70px; background: #fff; padding: 10px; border-radius: 70%; text-align: center; position: relative; z-index: 2;}
.footer-socail ul li a img{width: 30px; margin: 15px auto 0 auto;}

.footer-middle .footer-nav ul{margin: 0; padding: 0;}
.footer-middle .footer-nav ul li{margin: 0; padding: 0; font-size: 14px; font-weight: 400; margin-bottom: 7px;}
.footer-middle .footer-nav ul li a{display: block; text-decoration: none;}

.footer-middle  .footer-phone a{display: block; margin-bottom: 0px; font-size: 32px; font-weight: 700; text-decoration: none;}
.footer-middle  .footer-email a{ font-size: 22px; font-weight: 500; display: block;}
.footer-middle  .footer-address{margin-top: 15px; font-size: 16px; font-weight: 400;}

.footer-content{text-align: center; font-size: 12px;}
.footer-content p{font-size: 12px; color: #fff; margin-bottom: 10px;}
.footer-content ul{margin: 10px 0 0 0; padding: 0;}
.footer-content ul li{display: inline-block; width: auto; position: relative; margin: 0 8px; padding: 0;}
.footer-content ul li:first-child::before{display: none;}
.footer-content ul li::before{content: ""; position: absolute; left: -7px; top: 0; width: 1px; height: 100%; background: #fff;}

.footer-bottom{margin-top: 30px;}
.footer-bottom ul{margin: 0; padding: 0;}
.footer-bottom ul li{display: inline-block; width: auto; margin: 0 7px; padding: 0;}
.footer-bottom ul li a{display: block;}
.footer-bottom ul li a img{ max-height: 50px;}