.service-card{ border-radius: 40px !important;   box-shadow: 0px 0px 7.475px 0px rgba(0, 0, 0, 0.15) !important;}
.service-card button{padding: 50px 30px;

}
.service-card figure{background: url(../../../images/service-circle.svg); width: 170px; height: 170px; border-radius:50% ; margin: 0 auto; display: block;  overflow: hidden; position: relative;} 
.service-card figure img{width: 100px;   margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
.service-card .title{min-height: 90px;}