/* Overlay that covers the whole screen */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* White transparent layer */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it appears on top of everything */
  }
  
  /* Retain your existing .loading styles */
  .loading {
    --speed-of-animation: 0.9s;
    --gap: 6px;
    --first-color: #8fb6f0;
    --second-color: #2B74E2;
    --third-color: #124187;
    --fourth-color: #8fb6f0;
    --fifth-color: #2B74E2;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }
  
  .loading span {
    width: 4px;
    height: 50px;
    background: var(--first-color);
    animation: scale var(--speed-of-animation) ease-in-out infinite;
  }
  
  .loading span:nth-child(2) {
    background: var(--second-color);
    animation-delay: -0.8s;
  }
  
  .loading span:nth-child(3) {
    background: var(--third-color);
    animation-delay: -0.7s;
  }
  
  .loading span:nth-child(4) {
    background: var(--fourth-color);
    animation-delay: -0.6s;
  }
  
  .loading span:nth-child(5) {
    background: var(--fifth-color);
    animation-delay: -0.5s;
  }
  
  @keyframes scale {
    0%, 40%, 100% {
      transform: scaleY(0.05);
    }
  
    20% {
      transform: scaleY(1);
    }
  }
  