.hero-section h1 {
  font-size: 53px;
  line-height: 60px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
}

.hero-section img {
  width: 550px;
}

.hero-section p {
  font-size: 24px;
  line-height: 34px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
}

.slick-slider {
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
}

.slick-list.draggable {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}/*# sourceMappingURL=hero.css.map */