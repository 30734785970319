.text-primary {
  color: #2B74E2;
}

.bg-primary {
  background: #2B74E2;
}

.bg-secondary {
  background: #002E69;
}

.bg-grey {
  background: #EFF0FA;
}/*# sourceMappingURL=color.css.map */