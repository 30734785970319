@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500;6..12,600;6..12,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,700;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');


body{color: #212121; font-family: 'Nunito Sans', sans-serif;  font-weight: 400;}
a {
    text-decoration: none;
}
.letDiscuss .card {
    max-width: 850px;
    margin: 0 auto;
    border-radius: 40px;
    padding: 40px 50px;

    border-radius: 42.46px;
    background: #fff;
    box-shadow: 0px 0px 6.753px 0px rgba(0, 0, 0, 0.15);



}

.letDiscuss .heading{color: #2B74E2; font-size: 32px; line-height: 40px; font-weight: 700;}
.letDiscuss button{font-size: 20px; line-height: 26px; font-weight: 500;   font-family: "Roboto Flex", sans-serif; padding: 15px 40px; border-radius: 30px; }

.portfolio {
    overflow: hidden;
    position: relative;
   
}
.portfolio::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 63%;
    height: 100%;
    background: #2c74e2;
}

.portfolio .right-slider {
    padding: 0 !important;
}
.portfolio .right-slider img {
    width: 100px;
}
.portfolio .right-slider .webimage {
    position: relative;
    background: url("../../../images/laptop-phone.svg") no-repeat 0 0;
    background-size: 100% auto;
}
.portfolio h2 {
    padding-left: 100px;
    color: #fff;
    position: relative;
    z-index: 999;
    font-size: 57px;
    font-weight: 700;
    font-family: "Plus Jakarta Sans", sans-serif;
}


.portfolio h3{font-size: 45px;     font-weight: 700;
    font-family: "Plus Jakarta Sans", sans-serif;}

    .portfolio p{font-size: 24px; line-height: 36px; font-family: 'Nunito Sans', sans-serif;  font-weight: 400;}

.left-slider .text {
    padding-left: 100px;
    padding-top: 100px;
    color: #fff;
}

.portfolio .right-slider .webimage::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 90px;
    width: 320px;
    height: 650px;
    z-index: 2;
    background: url("../../../images/phone.svg") no-repeat 0 0;
    background-size: 100% auto;
}

.portfolio .right-slider .webimage .img2 {
    width: 960px;
    position: relative;
    left: 143px;
    top: 26px;
    height: 605px;
    overflow: hidden;
}
.portfolio .right-slider .webimage .img1 {
    position: absolute;
    top: 115px;
    margin-left: 13px;
    z-index: 9;
    border-radius: 47px;
    overflow: hidden;
    width: 291px;
    background: green;
    height: 625px;
}
.portfolio .right-slider .webimage .img1 img {
    border-radius: 47px;
}
.portfolio .right-slider .webimage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left top;
}
.portfolio .right-slider .slick-list,
.portfolio .right-slider .webimage {
    height: 860px;
}

.right-slider {
    position: relative;
    right: -15% !important;
}

.left-slider .slick-prev,
.left-slider .slick-next {
    bottom: -70px !important;
    top: auto !important;
    left: 50% !important;
    right: auto !important;
    display: block !important;
    opacity: 1 !important;
    transform: translate(0, 0) !important;
    width: 45px !important;
    height: 35px !important;
}
.left-slider .slick-prev::before,
.left-slider .slick-next::before {
    display: none;
}

.left-slider .slick-prev {
    left: 45% !important;
    background: url("../../../images/arrow_left_alt.svg") no-repeat 0 0;
}
.left-slider .slick-next {
    left: 55% !important;
    background: url("../../../images/arrow_right_alt.svg") no-repeat 0 0;
}
.bg-primary, .bg-primary li, .bg-primary a, .bg-primary div, .bg-primary h1, .bg-primary p,
.bg-secondary, .bg-secondary li, .bg-secondary a, .bg-secondary div, .bg-secondary h1, .bg-secondary p{color: #fff;}

.services-list-block .services-list-card{height: 100%; color: #fff; background:#2B74E2; border-radius: 20px; padding: 50px; position: relative; }
.services-list-block:nth-child(even) .services-list-card{ background:#002E69}
.services-list-block .services-list-card img{ position: absolute; bottom: 15px; right: 15px; width: 200px;}
.services-list-block .services-list-card ul li{position: relative; padding-left: 20px; margin-bottom: 5px; padding-bottom: 0;}
.services-list-block .services-list-card ul li::before{content: ""; position: absolute; left: 0; top: 13px; width: 10px; height: 10px; background: #fff; border-radius: 100%;}
.welcome-section{position: relative; padding-top: 80px;}
.welcome-section::before{content: ""; background: #EFF0FA; position: absolute; width: 100%; height: 56%; left: 0; bottom: 0; z-index: -1;}
.welcome-section figure{position: relative; width: 350px; height: 350px; background: #fff; border-radius: 70%; padding: 40px; margin: 0; top: -50px; }
.welcome-section figure img{width: 100%;}
.welcome-section .welcome-title{font-family: "Plus Jakarta Sans", sans-serif;  font-weight: 700; font-size: 45px;}
.welcome-section p{font-size: 24px; line-height: 32px; font-family: 'Nunito Sans', sans-serif;  font-weight: 400;}

.blog .card{border-radius: 42px;
    background: #fff;
    box-shadow: 0px 0px 6.753px 0px rgba(0, 0, 0, 0.15);}

    .blog .card figure{margin: 0; padding: 0; border-top-left-radius:42px ; border-top-right-radius: 42px; overflow: hidden; height: 280px; }

    .blog .card img{border-top-left-radius:42px ; border-top-right-radius: 42px; width: 100%; height: 100%; object-fit: cover;}

    .blog   .card-content{padding: 40px 30px; }

    .blog   .card-content .title{color: #4A3232; font-size: 32px; font-weight: 700; font-family: "Plus Jakarta Sans", sans-serif; min-height: 140px;}

    .blog   .card-content p{font-size: 16px; line-height: 26px; font-family: 'Nunito Sans', sans-serif;  font-weight: 400; margin-bottom: 50px; min-height: 140px;}

    .blog   .card-content .card-actions{justify-content: right;}
    .blog   .card-content .card-actions button{font-size: 22px; line-height: 24px; font-family: "Plus Jakarta Sans", sans-serif; padding: 15px 35px; border-radius: 30px;}

    .about-top-section h1{ font-size: 45px; line-height: 50px; font-family: "Plus Jakarta Sans", sans-serif; font-weight: 700; color: #000; margin-bottom: 30px;}
    .about-top-section h1 span{display: block; color: #2B74E2;}

    .about-top-section p{font-size: 24px; line-height: 32px; font-family: 'Nunito Sans', sans-serif;  font-weight: 400; }
    .about-top-section figure{margin: 0 0 0 100px; padding: 0;}

    .vision-section{background: url('../../../images/about-bg.svg') no-repeat ; background-size: cover; background-position: 0 0; padding: 500px 0 100px 0; }
    
    .vision-section .card{border-radius: 47px;
        background: #fff;
        box-shadow: 0px 0px 7.475px 0px rgba(0, 0, 0, 0.15); padding: 30px; margin-bottom: 50px;  }

            
    .vision-section .card figure img{width: 320px;}

    .vision-section .card  ul li{font-size: 24px; line-height: 28px; color: #000; position: relative; padding-left: 50px;}
    .vision-section .card  ul li svg{ position: absolute; left: 0; top: 8px;}

    .about-experience-section .block{text-align: center; border-left: solid 1px #ccc; padding-left: 50px;}
    .about-experience-section .block:first-child{border-left: none;}
    .about-experience-section .block p{font-size: 24px; line-height: 28px; font-weight: 400;   font-family: "Roboto Flex", sans-serif; padding-bottom: 15px !important;}
    .about-experience-section .block h6{color: #2B74E2 ; font-size: 45px; line-height: 50px; font-weight: 700;   font-family: "Roboto Flex", sans-serif; }

    .solution-top-section h2 span{color: #2B74E2 ;  font-size: 45px; line-height: 50px; font-weight: 700;  }
    .solution-top-section p{font-size: 24px; line-height: 30px; font-weight: 400;   font-family: "Roboto Flex", sans-serif;}
    .weoffer-section{background: #2B74E2; color: #fff; }
    .weoffer-section h1{margin-bottom: 50px;}
    .weoffer-section figure{margin: 0;}
    .weoffer-section .content{padding: 0 50px;}
    .weoffer-section .block{margin-bottom: 50px;}
    .weoffer-section .block:nth-child(odd) .content{order: 1;}
    .weoffer-section .block:nth-child(odd) .pic{order: 2;}
    .weoffer-section p{font-size: 24px; line-height: 34px; font-weight: 400;   font-family: "Roboto Flex", sans-serif; }

    .contact-page-section input{width: 100%;}
    .contact-page-section .content{padding: 0 30px;}
    .contact-page-section button{font-size: 22px; line-height: 24px; font-family: "Plus Jakarta Sans", sans-serif; padding: 15px 35px; border-radius: 30px;}