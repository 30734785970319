.process-card {
  border-radius: 40px !important;
  padding: 15px 25px;
  box-shadow: 0px 0px 6.753px 0px rgba(0, 0, 0, 0.15);
}

.process-card figure {
  width: 70px;
  height: 70px;
  background: #EDF0FF;
  border-radius: 16px;
  margin: 0;
  position: relative;
}

.process-card figure img {
  width: 50px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.process-card p {
  font-size: 14px;
}/*# sourceMappingURL=index.css.map */