.business-model ul li {
  cursor: pointer;
}

.business-model .right-block ul li {
  display: none;
}

.business-model .right-block ul li figure {
  max-width: 100%;
  overflow: hidden;
}

.business-model .right-block ul li.active {
  display: block;
}

.business-model .left-block ul li {
  position: relative;
  padding-left: 40px;
  margin-bottom: 30px;
  display: block;
}

.business-model .left-block ul li::before {
  content: "";
  width: 3px;
  height: 100%;
  background: #D3D3D3;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.business-model .left-block ul li::after {
  content: "";
  width: 3px;
  height: 0;
  background: #2B74E2;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

@keyframes shrink-height {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}
.business-model .left-block ul li.active::before {
  width: 4px;
}

.business-model .left-block ul li.active::after {
  width: 4px;
  height: 100%;
  animation: shrink-height 3.5s infinite;
}

.business-model .left-block ul li h4 {
  color: #A59999;
}

.business-model .left-block ul li p {
  color: #A6A6A6;
}

.business-model .left-block ul li.active h4 {
  color: #DE3730;
}

.business-model .left-block ul li.active p {
  color: #000;
}/*# sourceMappingURL=index.css.map */