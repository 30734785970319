.nav ul {
  display: inline-block;
  width: auto;
}

.nav ul li {
  display: inline-block;
  width: auto;
  margin-right: 40px;
  position: relative;
}

.nav ul li a {
  color: #212121;
  padding: 5px 15px;
}

.nav ul li .top-button {
  color: #ffffff;
  padding: 10px 25px;
  text-transform: none;
}

.nav ul ul {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
}

.nav ul li:hover ul {
  display: block;
}/*# sourceMappingURL=nav.css.map */